import React, { ReactNode, useState, useEffect, useRef } from 'react';
import { getAllLayerGroups } from './api/layer-groups';
import { getAccessTokens } from './api/access-tokens';
import { registerVisitor } from './api/visitors';
import { getMapQuerystringParameters, setInitialBounds, parseMapQuerystringParameters, showMetadataPopup, initLeafletDraw } from './logic/map-helper';
import { searchArtdatabankenObservationsForShapes, createArtdatabankenMarkers, removeArtdatabankenMarkers } from './logic/artdatabanken-helper';
import { setUrl } from './logic/browser-helper';
import { globalMaximumZoomLevel, createCrs, createLeafletLayer, createGoogleSatelliteLayer, createLantmäterietTopowebbLayer, createLantmäterietHistoricOrtophotoLayer, createLeafletMeasureOptions } from './leaflet/leaflet-helper';
import { initializeGeolocation } from './logic/geolocation';
import { getAreasForLocation } from './api/areas';
import L from 'leaflet';
import { ConfigProvider } from 'antd';
import antLocaleEn from 'antd/locale/en_US';
import antLocaleSv from 'antd/locale/sv_SE';
import AppContext from './models/AppContext';
import AccessTokens from './models/AccessTokens';
import Area from './models/Area';
import Menu from './components/Menu';
import Toolbar from './components/Toolbar';
import InformationModal from './components/InformationModal';
import LabelModal from './components/LabelModal';
import ArtdatabankenObservationsModal from './components/ArtdatabankenObservationsModal';
import LayerGroup, { enhanceLayerGroups } from './models/LayerGroup';
import LeafletDrawShape from './models/LeafletDrawShape';
import KeyedLeafletLayer from './models/KeyedLeafletLayer';
import browserLang from 'browser-lang';
import queryString from 'query-string';
import store from 'store2';
import drawLocales from './leaflet-draw-locales/index';
import { getResource } from './resources/resource-manager';

import 'leaflet/dist/leaflet.css';
import './Map.css';
import './leaflet-draw/leaflet.draw.css';

import 'leaflet-measure-ext/dist/leaflet-measure.css';
require('leaflet-measure-ext/dist/leaflet-measure.js');
require('./leaflet/overview-map.js');
require('./leaflet/spinner.js');
require('./leaflet-draw/Control.Draw.js');
require('./leaflet-draw/Leaflet.draw.js');
require('./leaflet-draw/Leaflet.Draw.Event.js');
require('./leaflet-draw/Leaflet.Draw.Event.js');

require('./leaflet-draw/edit/handler/Edit.Poly.js');
require('./leaflet-draw/edit/handler/Edit.SimpleShape.js');
require('./leaflet-draw/edit/handler/Edit.Rectangle.js');
require('./leaflet-draw/edit/handler/Edit.Marker.js');
require('./leaflet-draw/edit/handler/Edit.CircleMarker.js');
require('./leaflet-draw/edit/handler/Edit.Circle.js');


require('./leaflet-draw/draw/handler/Draw.Feature.js');
require('./leaflet-draw/draw/handler/Draw.Polyline.js');
require('./leaflet-draw/draw/handler/Draw.Polygon.js');
require('./leaflet-draw/draw/handler/Draw.SimpleShape.js');
require('./leaflet-draw/draw/handler/Draw.Rectangle.js');
require('./leaflet-draw/draw/handler/Draw.Marker.js');
require('./leaflet-draw/draw/handler/Draw.CircleMarker.js');
require('./leaflet-draw/draw/handler/Draw.Circle.js');
require('./leaflet-draw/draw/handler/Draw.Label.js');

require('./leaflet-draw/ext/TouchEvents.js');
require('./leaflet-draw/ext/LatLngUtil.js');
require('./leaflet-draw/ext/GeometryUtil.js');
require('./leaflet-draw/ext/LineUtil.Intersect.js');
require('./leaflet-draw/ext/Polyline.Intersect.js');
require('./leaflet-draw/ext/Polygon.Intersect.js');

require('./leaflet-draw/Tooltip.js');
require('./leaflet-draw/Toolbar.js');

require('./leaflet-draw/draw/DrawToolbar.js');
require('./leaflet-draw/edit/EditToolbar.js');
require('./leaflet-draw/edit/handler/EditToolbar.Edit.js');
require('./leaflet-draw/edit/handler/EditToolbar.Delete.js');

const createBackgroundMapLayers = (accessTokens : AccessTokens) : KeyedLeafletLayer[] => [
    createGoogleSatelliteLayer(),
    createLantmäterietTopowebbLayer(accessTokens.lantmäteriet.access_token),
    createLantmäterietHistoricOrtophotoLayer('LantmäterietHistoriskaOrtofoton1960', 'OI.Histortho_60', accessTokens.lantmäteriet.access_token),
    createLantmäterietHistoricOrtophotoLayer('LantmäterietHistoriskaOrtofoton1975', 'OI.Histortho_75', accessTokens.lantmäteriet.access_token)
];


const querystringParameters = queryString.parse(document.location.search);

if(querystringParameters.language) {
    store('language', querystringParameters.language);
}

const initialAppContext : AppContext = {
    selectedBackgroundMapLayerKey: parseMapQuerystringParameters()?.background ?? 'GoogleSatellite',
    language: store('language') ?? browserLang({ languages: ['sv', 'en'], fallback: 'en' }),
    shapes: [],
    artdatabankenObservations: []
};

store('language', initialAppContext.language);

const drawLocale = drawLocales(initialAppContext.language as any);
(L as any).drawLocal = drawLocale;

const antLocale = initialAppContext.language === 'sv'
    ? antLocaleSv
    : antLocaleEn;

document.documentElement.setAttribute('lang', initialAppContext.language);

const Map = () => {
    const [ leafletLayers, setLeafletLayers ] = useState<KeyedLeafletLayer[] | undefined>();
    const [ determineInitialLocationFromGeolocation ] = useState<boolean>(parseMapQuerystringParameters() === undefined);
    const [ mobileMenuVisible, setMobileMenuVisible ] = useState<boolean>(false);
    const [ geolocationEnabled, setGeolocationEnabled ] = useState<boolean>(false);
    const [ layerGroups, setLayerGroups ] = useState<LayerGroup[] | undefined>();
    const [ backgroundMapLayers, setBackgroundMapLayers ] = useState<KeyedLeafletLayer[] | undefined>();
    const [ selectedBackgroundMapLayerKey, setSelectedBackgroundMapLayerKey ] = useState<string>(initialAppContext.selectedBackgroundMapLayerKey);
    const [ hoverPosition, setHoverPosition ] = useState<L.LatLng | undefined>(undefined);
    const [ informationModalOpen, setInformationModalOpen ] = useState<boolean>(false);
    const [ informationModalContent, setInformationModalContent ] = useState<ReactNode | undefined>(undefined);
    const [ informationModalTitle, setInformationModalTitle ] = useState<string | undefined>(undefined);
    const [ labelModalOpen, setLabelModalOpen ] = useState<boolean>(false);
    const [ artdatabankenObservationsModalOpen, setArtdatabankenObservationsModalOpen ] = useState<boolean>(false);
    const [ artdatabankenObservations, setArtdatabankenObservations ] = useState<any[] | undefined>();
    const [ shapesChangedSinceArdatabankenObservationsModalShown, setShapesChangedSinceArdatabankenObservationsModalShown ] = useState<boolean>(false);

    const mapRef = useRef<L.Map>();
    const metadataPopupRef = useRef<L.Popup>();
    const overviewMapRef = useRef();
    const spinnerRef = useRef();
    const appContextRef = useRef<AppContext>(initialAppContext);
    const geolocationMarkerRef = useRef<L.Marker>();
    const artdatabankenMarkersRef = useRef<L.Marker[] | undefined>();
    const dataFetchedRef = useRef<boolean>(false);
    const hasPannedToGeolocationRef = useRef<boolean>(false);
    const labelLayerArgsRef = useRef<any>(undefined);
    const accessTokensRef = useRef<any>(undefined);

    // click on map
    const handleMapClick = async (e : L.LeafletMouseEvent) => {
        (spinnerRef.current as any).setVisible(true);
        const areas = await getAreasForLocation(e.latlng.lat, e.latlng.lng);
        appContextRef.current = {
            ...appContextRef.current!,
            hoverPosition: e.latlng,
            clickedAreas: areas
        };
        setHoverPosition(e.latlng);
        
        const layerGroups = appContextRef.current.layerGroups;
        const visibleAreas = areas.filter(area => layerGroups?.find(lg => lg.key === area.layerGroupKey)?.visible ?? false);
        
        if(visibleAreas.length > 0) {
            metadataPopupRef.current = showMetadataPopup(mapRef.current!, e.latlng, visibleAreas, layerGroups);
        }
        (spinnerRef.current as any).setVisible(false);
    };

    const handleMapZoomEnd = (e : L.LeafletEvent) => {
        setUrl(getMapQuerystringParameters(e.target, appContextRef.current));
        (overviewMapRef.current as any).setBounds(e.target.getBounds());
    };

    const handleMapMoveEnd = (e : L.LeafletEvent) => {
        setUrl(getMapQuerystringParameters(e.target, appContextRef.current));
        (overviewMapRef.current as any).setBounds(e.target.getBounds());
    };

    const handleMapMouseMove = (e: L.LeafletMouseEvent) => {
        appContextRef.current = {
            ...appContextRef.current!,
            hoverPosition: e.latlng
        };
        setHoverPosition(e.latlng);
    };

    const handleMapMouseOut = (e: L.LeafletMouseEvent) => {
        appContextRef.current = {
            ...appContextRef.current!,
            hoverPosition: undefined
        };
    };

    const handleLayerGroupVisibilityChange = (layerGroup : LayerGroup) => {
        const updatedLayerGroups = [...appContextRef.current!.layerGroups ?? []] as LayerGroup[];
        const selectedLayerGroup = updatedLayerGroups.find(o => o.key === layerGroup.key)!;
        selectedLayerGroup.visible = !selectedLayerGroup.visible;
        appContextRef.current = {
            ...appContextRef.current!,
            layerGroups: updatedLayerGroups
        };
        setLayerGroups(updatedLayerGroups);

        const leafletLayer = leafletLayers?.find(o => o.key === selectedLayerGroup.key)!;
        const map = mapRef.current!;
        if(selectedLayerGroup.visible) {
            leafletLayer.layer.addTo(map);
        } else {
            leafletLayer.layer.remove();
        }
        setUrl(getMapQuerystringParameters(mapRef.current, appContextRef.current));
    };

    const handleSelectedBackgroundMapLayerKeyChange = (backgroundMapLayerKey : string) => {
        appContextRef.current = {
            ...appContextRef.current!,
            selectedBackgroundMapLayerKey: backgroundMapLayerKey
        };
        setSelectedBackgroundMapLayerKey(backgroundMapLayerKey);
        const map = mapRef.current!;
        appContextRef.current!.backgroundMapLayers!.forEach(backgroundMapLayer => {
            backgroundMapLayer.layer.remove();
            if(backgroundMapLayer.key === backgroundMapLayerKey) {
                backgroundMapLayer.layer.addTo(map);
            }
        });
        setUrl(getMapQuerystringParameters(mapRef.current, appContextRef.current));
    };

    const handleToolbarMenuButtonClick = () => {
        setMobileMenuVisible(!mobileMenuVisible);
    };

    const handleLanguageChange = (newLanguage : string) => {
        store('language', newLanguage);
        document.location.reload();
    };

    const handleInformationModalClose = () => {
        setInformationModalOpen(false);
    };

    const handleLabelModalOk = (text : string) => {
        setLabelModalOpen(false);
        const { e, drawLayerGroup } = labelLayerArgsRef.current;
        e.layer.setText(text);
        drawLayerGroup.addLayer(e.layer);
    };
    const handleLabelModalCancel = () => {
        setLabelModalOpen(false);
    };

    const handleSearchArtdatabankenObservations = async () => {
        if(appContextRef.current.shapes.length === 0) {
            setInformationModalTitle(getResource('artdatabanken.searchObservationsToolInfoTitle'));
            setInformationModalContent(getResource('artdatabanken.searchObservationsToolInfoBody'));
            setInformationModalOpen(true);
        } else {
            if(shapesChangedSinceArdatabankenObservationsModalShown) {
                appContextRef.current = {
                    ...appContextRef.current,
                    artdatabankenTaxonIds: undefined
                };
                await handleSearchArtdatabankenObservationsHelper();
            }
            setArtdatabankenObservationsModalOpen(true);
            setShapesChangedSinceArdatabankenObservationsModalShown(false);
        }
    };

    const handleSearchArtdatabankenObservationsHelper = async() => {
        (spinnerRef.current as any).setVisible(true);
        const { observations, error } = await searchArtdatabankenObservationsForShapes(appContextRef.current!.shapes, appContextRef.current!.artdatabankenTaxonIds);
        if(error) {
            setInformationModalTitle(getResource('artdatabanken.errorTitle'));
            setInformationModalContent(getResource('artdatabanken.errorContent'));
            setInformationModalOpen(true);
        }
        const map = mapRef.current!;
        appContextRef.current = {
            ...appContextRef.current!,
            artdatabankenObservations: observations
        };
        setArtdatabankenObservations(observations);
        removeArtdatabankenMarkers(map, artdatabankenMarkersRef.current);
        artdatabankenMarkersRef.current = createArtdatabankenMarkers(map, observations);
        (spinnerRef.current as any).setVisible(false);
    };

    const handleArtdatabankenTaxonFilter = async (taxonId : number | undefined, onFilterApplied: () => void) => {
        appContextRef.current = {
            ...appContextRef.current!,
            artdatabankenTaxonIds: taxonId ? [taxonId] : undefined
        };
        await handleSearchArtdatabankenObservationsHelper();
        onFilterApplied();
    };

    const handleArtdatabankenObservationsModalClose = () => {
        setArtdatabankenObservationsModalOpen(false);
    };

    const handleAreaSelected = (area : Area | undefined) => {
        const map = mapRef.current;
        if(area && area.boundingBox && map) {
            const latLng = L.latLng(
                (area.boundingBox?.south + area.boundingBox?.north) / 2,
                (area.boundingBox?.west + area.boundingBox?.east) / 2
            );
            map.setView(latLng, Math.max(15, map.getZoom()));
            metadataPopupRef.current = showMetadataPopup(mapRef.current!, latLng, [area], layerGroups);
        }
    };

    // geolocation API
    useEffect(() => {
        const onGeolocationPositionSuccess = (position: GeolocationPosition) => {
            if(!geolocationMarkerRef.current) {
                // first geolocation received
                const map = mapRef.current;
                const icon = L.icon({
                    iconUrl: '/assets/position-marker.svg',
                    iconSize: L.point(21, 21),
                    iconAnchor: L.point(10, 10),
                    className: 'current-position-icon'
                });
                geolocationMarkerRef.current = L.marker([position.coords.latitude, position.coords.longitude], { icon });
                if(map) {
                    geolocationMarkerRef.current.addTo(map);
                }
            } else {
                // subsequent geolocation received
                geolocationMarkerRef.current.setLatLng([position.coords.latitude, position.coords.longitude]);
            }
            appContextRef.current = {
                ...appContextRef.current!,
                geolocationPosition: position
            };
            setGeolocationEnabled(true);
        };
    
        const onGeolocationPositionError = (args: GeolocationPositionError) => {
            //console.log('onGeolocationPositionError', args);
        };

        const result = initializeGeolocation(
            onGeolocationPositionSuccess,
            onGeolocationPositionError,
            onGeolocationPositionSuccess,
            onGeolocationPositionError
        );
        appContextRef.current = {
            ...appContextRef.current!,
            geolocationInitializationResult: result
        };

        return () => {
            if(result.watchPositionHandle) {
                navigator.geolocation.clearWatch(result.watchPositionHandle);
            }
        };
    }, []);

    // init Leaflet map
    useEffect(() => {
        const zooms : number[] = [];

        for(let zoom = 0 ; zoom <= globalMaximumZoomLevel; zoom++) {
            zooms.push(zoom);
        }

        const mapOptions = {
            crs: createCrs(),
            renderer: L.canvas(),
            minZoom: 0,
            maxZoom: globalMaximumZoomLevel,
            zooms
        };
        const map = new L.Map('map', mapOptions);

        const measureControl = (L.control as any).measure(createLeafletMeasureOptions());
        measureControl.addTo(map);

        const scaleOptions : L.Control.ScaleOptions = {
            metric: true,
            imperial: false,
            maxWidth: 200,
            position: 'bottomright'
        };
        const scaleControl = L.control.scale(scaleOptions);
        scaleControl.addTo(map);

        const overviewMapOptions = {
            position: 'bottomleft'
        };
        const overviewMap = (L.control as any).overviewMap(overviewMapOptions);
        overviewMap.addTo(map);
        overviewMapRef.current = overviewMap;

        const spinnerOptions = {
            position: 'topleft'
        };
        const spinner = (L.control as any).spinner(spinnerOptions);
        spinner.addTo(map);
        spinnerRef.current = spinner;

        initLeafletDraw(map, {
            onAddLabel: (e : any, drawLayerGroup : L.FeatureGroup) => {
                setLabelModalOpen(true);
                labelLayerArgsRef.current = {
                    e,
                    drawLayerGroup
                };
            },
            onChangeShapes: (shapes : LeafletDrawShape[]) => {
                appContextRef.current = {
                    ...appContextRef.current!,
                    shapes
                };
                if(shapes.length === 0) {
                    setArtdatabankenObservations(undefined);
                    removeArtdatabankenMarkers(map, artdatabankenMarkersRef.current!);
                    artdatabankenMarkersRef.current = undefined;
                }
                setShapesChangedSinceArdatabankenObservationsModalShown(true);
            }
        });

        map.on('click', handleMapClick);
        map.on('zoomend', handleMapZoomEnd);
        map.on('moveend', handleMapMoveEnd);
        map.on('mousemove', handleMapMouseMove);
        map.on('mouseout', handleMapMouseOut);
        setInitialBounds(map);

        mapRef.current = map;

        return () => {
            map.remove();
        };
    }, []);
    
    // fetch map layers from backend
    useEffect(() => {
        const fetchData = async () => {
            if(dataFetchedRef.current) {
                return;
            }
            dataFetchedRef.current = true;
            // create enhanced layer groups
            // create leaflet tile layers
            const lg = await getAllLayerGroups();
            enhanceLayerGroups(lg, parseMapQuerystringParameters());
            const accessTokens = await getAccessTokens();
            accessTokensRef.current = accessTokens;
            refreshBackgroundMapLayers(accessTokens);

            appContextRef.current = {
                ...appContextRef.current!,
                layerGroups: lg
            };
            setLayerGroups(lg);

            const lls = lg.map(createLeafletLayer) as KeyedLeafletLayer[];
            setLeafletLayers(lls);

            lg.forEach((layerGroup : LayerGroup) => {
                const leafletLayer = lls?.find(o => o.key === layerGroup.key);
                if(layerGroup.visible && leafletLayer) {
                    leafletLayer.layer.addTo(mapRef.current!);
                }
            });
            setUrl(getMapQuerystringParameters(mapRef.current, appContextRef.current));
        };

        fetchData().catch(console.error);
    }, []);

    // update geolocation position
    useEffect(() => {
        const map = mapRef.current;
        const geolocationMarker = geolocationMarkerRef.current;
        const geolocationPosition = appContextRef.current.geolocationPosition;
        if(map && geolocationPosition?.coords) {
            if(!hasPannedToGeolocationRef.current) {
                if(determineInitialLocationFromGeolocation) {
                    map.setView([geolocationPosition.coords.latitude, geolocationPosition.coords.longitude], 13);
                }
                hasPannedToGeolocationRef.current = true;
            }
            if(geolocationMarker) {
                geolocationMarker.setLatLng([geolocationPosition.coords.latitude, geolocationPosition.coords.longitude]);
            }
        }
    }, [appContextRef.current.geolocationPosition, determineInitialLocationFromGeolocation]);    

    const refreshBackgroundMapLayers = (accessTokens : AccessTokens) => {
        const bml = createBackgroundMapLayers(accessTokens);
        const map = mapRef.current!;
        
        (appContextRef.current!.backgroundMapLayers ?? []).forEach(backgroundMapLayer => {
            backgroundMapLayer.layer.remove();
        });
        appContextRef.current = {
            ...appContextRef.current!,
            backgroundMapLayers: bml,
            backgroundMapLayerRefreshTime: Date.now()
        };
        setBackgroundMapLayers(bml);

        bml.forEach(backgroundMapLayer => {
            if(backgroundMapLayer.key === appContextRef.current.selectedBackgroundMapLayerKey) {
                backgroundMapLayer.layer.addTo(map);
            }
        });
    };

    // Lantmäteriet has a very stupid access token management
    // once a new token is generated, all previous tokens expire
    // therefore, we need to keep track of token expiration and generation
    // a new token is generated every 30 minutes by a timer-triggered Azure function
    useEffect(() => {
        const refresh = async () => {
            const accessTokens = await getAccessTokens();
            refreshBackgroundMapLayers(accessTokens);
            accessTokensRef.current = accessTokens;
        };

        const monitorTokenRefreshIntervalHandle = window.setInterval(() => {
            const accessTokens = accessTokensRef.current;
            if(accessTokens && 
                new Date().getTime() > accessTokens.lantmäteriet.browser_expiration_time) {
                // the token has expired, fetch a new one
                refresh().catch(console.error);
            }
        }, 1000 /* monitor whether token has expired every second */);
        return () => window.clearInterval(monitorTokenRefreshIntervalHandle);
    }, []);

    // update visitor count
    useEffect(() => {
        if(document.location.host.substring(0, 9) !== 'localhost') {
            const register = async () => {
                const lastVisitTime = store('lastVisitTime');
                await registerVisitor(lastVisitTime);
                const currentTime = new Date().getTime();
                store('lastVisitTime', currentTime);
            };
            register().catch(console.error);
        }
    }, []);

    const appContext = appContextRef.current as AppContext;

    return (
        <ConfigProvider locale={antLocale}>
            <div id="map-app">
                <Toolbar
                    map={mapRef.current}
                    appContext={appContext}
                    hoverPosition={hoverPosition}
                    geolocationEnabled={geolocationEnabled}
                    onMenuButtonClick={handleToolbarMenuButtonClick}
                    onLanguageChange={handleLanguageChange}
                    onSearchArtdatabankenObservations={handleSearchArtdatabankenObservations}
                    onAreaSelected={handleAreaSelected}
                />
                <InformationModal
                    open={informationModalOpen}
                    onClose={handleInformationModalClose}
                    title={informationModalTitle}
                >
                    {informationModalContent}
                </InformationModal>
                <LabelModal
                    open={labelModalOpen}
                    onOk={handleLabelModalOk}
                    onCancel={handleLabelModalCancel}
                />
                {
                    artdatabankenObservations &&
                    (
                        <ArtdatabankenObservationsModal
                            observations={artdatabankenObservations}
                            open={artdatabankenObservationsModalOpen}
                            onTaxonFilter={handleArtdatabankenTaxonFilter}
                            onClose={handleArtdatabankenObservationsModalClose}
                        />
                    )
                }
                <div id="main">
                    <div id="map-container">
                        <div id="map"/>
                    </div>
                    <div id="menu-container" className={'no-print' + (mobileMenuVisible ? ' mobile-menu-visible' : '')}>
                        {
                            layerGroups && backgroundMapLayers &&
                            (
                                <Menu
                                    layerGroups={layerGroups!}
                                    backgroundMapLayers={backgroundMapLayers}
                                    leafletLayers={leafletLayers ?? []}
                                    selectedBackgroundMapLayerKey={selectedBackgroundMapLayerKey}
                                    onLayerGroupVisibilityChange={handleLayerGroupVisibilityChange}
                                    onSelectedBackgroundMapLayerKeyChange={handleSelectedBackgroundMapLayerKeyChange}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </ConfigProvider>
    );
};

export default Map;
