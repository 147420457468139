const sv = {
    backgroundMap: 'Bakgrundskarta',
    layers: 'Lager',
    layerDescriptionModalTitle: 'Lagerbeskrivning',
    shareModalTitle: 'Länk till vald position på kartan',
    labelModalTitle: 'Skriv in etikettens text',
    hectaresAbbreviated: 'ha',
    ok: 'Ok',
    cancel: 'Avbryt',
    decimalPoint: ',',
    thousandsSeparator: ' ',
    backgroundMapSettings: 'Inställningar för bakgrundskarta',
    opacity: 'Genomskinlighet',
    downloadingMap: 'Laddar ner karta...',
    close: 'Stäng',

    'attributions.GoogleSatellite': 'Kartdata © Google',
    'attributions.LantmäterietTopowebb': '© <a href="https://www.lantmateriet.se">Lantmäteriet</a> Topografisk webbkarta Visning, översiktlig',
    'attributions.LantmäterietHistoriskaOrtofoton1960': '© <a href="https://www.lantmateriet.se">Lantmäteriet</a> Ortofoto historiska Visning, 1960',
    'attributions.LantmäterietHistoriskaOrtofoton1975': '© <a href="https://www.lantmateriet.se">Lantmäteriet</a> Ortofoto historiska Visning, 1975',
    'layerGroups.GoogleSatellite': 'Satellitbild',
    'layerGroups.LantmäterietTopowebb': 'Topografisk karta',
    'layerGroups.LantmäterietHistoriskaOrtofoton1960': 'Historiskt ortofoto, 1960',
    'layerGroups.LantmäterietHistoriskaOrtofoton1975': 'Historiskt ortofoto, 1975',
    'layerGroups.RemainingClassificationData': 'Äldre skog & kontinuitetsskog 1.0',
    'layerGroups.RemainingClassificationData.subtitle': '<ul><li><span class="legend-square remaining-classification-data-1"></span>Höga naturvärden</li><li><span class="legend-square remaining-classification-data-2"></span>Troliga naturvärden</li><li><span class="legend-square remaining-classification-data-3"></span>Potentiell äldre skog eller kontinuitetsskog</li>',
    'layerGroups.ExecutedLoggingClassificationData': 'Avverkad äldre skog & kontinuitetsskog',
    'layerGroups.ExecutedLoggingClassificationData.subtitle': '<ul><li><span class="legend-square executed-logging-classification-data-1"></span>Höga naturvärden</li><li><span class="legend-square executed-logging-classification-data-2"></span>Troliga naturvärden</li><li><span class="legend-square executed-logging-classification-data-3"></span>Potentiell äldre skog eller kontinuitetsskog</li>',
    'layerGroups.FormalProtection': 'Formellt skydd',
    'layerGroups.OtherProtectionTypes': 'Övriga skyddsformer',
    'layerGroups.ConservationValues': 'Naturvärden',
    'layerGroups.NotifiedLoggingLast42Days': 'Nya avverkningsanmälningar',
    'layerGroups.NotifiedLoggingOlderThan42Days': 'Avverkningsanmälningar',
    'layerGroups.ExecutedLogging': 'Utförda avverkningar',
    'layerGroups.HabitatTypes': 'Naturtyper',
    'layerGroups.MainLandowners': 'Stora skogsägares markinnehav',
    'layers.RemainingClassificationData': 'Äldre skog & kontinuitetsskog 1.0',
    'layers.ExecutedLoggingClassificationData': 'Avverkad äldre skog & kontinuitetsskog',
    'layers.NationalParks': 'Nationalparker',
    'layers.NatureReserves': 'Naturreservat',
    'layers.Nature2000SCI': 'Natura 2000 Art- & Habitat',
    'layers.ForestBiotopeProtectionAreas': 'Biotopskyddsområden',
    'layers.OtherBiotopeProtectionAreas': 'Övriga biotopskyddsområden',
    'layers.NatureConservationAgreements': 'Naturvårdsavtal',
    'layers.NatureConservationAgreementsOther': 'Naturvårdsavtal, övriga',
    'layers.Nature2000SPA': 'Natura 2000 fågeldirektivet',
    'layers.NatureMemorials': 'Naturminnen',
    'layers.NatureConservationAreas': 'Naturvårdsområden',
    'layers.AnimalAndPlantProtectionAreas': 'Djur- och växtskyddsområden',
    'layers.CulturalReserves': 'Kulturreservat',
    'layers.ScenicProtectionAreas': 'Landskapsbildsskyddsområden',
    'layers.InterimProhibitionAreas': 'Interimistiska förbud',
    'layers.SFAWoodlandKeyHabitats': 'Skogsstyrelsens nyckelbiotoper',
    'layers.ForestrysWoodlandKeyHabitats': 'Storskogsbrukets nyckelbiotoper',
    'layers.SFAConservationValues': 'Skogsstyrelsens naturvärden',
    'layers.StateConservationValueForests': 'Skyddsvärda statliga skogar',
    'layers.SFASwampForests': 'Skogsstyrelsens sumpskogar',
    'layers.NotifiedLoggingLast42Days': 'Nya avverkningsanmälningar',
    'layers.NotifiedLoggingOlderThan42Days': 'Avverkningsanmälningar',
    'layers.ExecutedLogging': 'Utförda avverkningar',
    'layers.NationalWetlandInventory': 'Våtmarksinventeringen',
    'layers.MainLandowners': 'Stora skogsägares markinnehav',
    
    'layerDescriptions.RemainingClassificationData': '<p>Detta lager innehåller potentiell kontinuitetsskog och äldre skog som identifierats via fjärranalys, samt områden (Höga naturvärden &amp; Troliga naturvärden) som olika öppna data och inventeringar visat har höga naturvärden (inventering &amp; öppna data) eller troliga naturvärden (artdata och annan öppna data). Fjärranalysen visar skogsområden som sannolikt inte kalavverkats sedan 1950-talet och därför är potentiell äldre skog eller kontinuitetsskog beroende på landanvändningshistoria och vilken region analysen utförts. Version 1.0 av detta lager bygger på en hybrid mellan öppna data från Naturvårdsverket (Dalarna, Värmland och norrlandslänen) och Skogsmonitors egen fjärranalys (länen i resten av Svealand samt Götaland). Bearbetningen och klassningen är gjord av Skogsmonitor. Detta lager kommer förbättras och uppdateras för hela landet.</p><p><strong>Höga naturvärden</strong>: Områden inom fjärranalysen som öppna data (inventering ligger oftast som grund) bekräftar har höga naturvärden, samt äldre skog och kontinuitetsskog inom formellt skyddad natur.<br/><strong>Troliga naturvärden</strong>: Områden inom fjärranalysen med potentiell äldre skog och kontinuitetsskog där det i hela eller delar av en polygon troligen finns naturvärden eller höga naturvärden, baserat på offentlig och öppna data som artfynd och geodata.<br/><strong>Potentiell äldre skog eller kontinuitetsskog (övrig areal)</strong>: Fjärranalys baserad på AI, klassning, och förändringsanalys, som visar skog som sannolikt inte kalavverkats sedan 1950-talet och därför är potentiell äldre skog och/eller kontinuitetsskog beroende på landanvändningshistoria och vilken region analysen utförts. Denna del av skiktet innehåller sannolikt en stor mängd skog med höga naturvärden, vars naturvärden idag är okända för Skogsmonitor.</p>',
    'layerDescriptions.ExecutedLoggingClassificationData': '<p>Detta lager visar skogar från lagret "Äldre skog & kontinuitetsskog" som har avverkats sedan början av 2022. Observera att detta lager endast visar sentida förlust av äldre skog och kontinuitetsskog (inkl. potentiell). På vissa platser syns smala remsor med avverkad "Äldre skog & kontinuitetsskog". Detta beror ofta på att avgränsningarna i detta lager inte alltid stämmer överens med verkligheten och att avverkningar som skett strax intill dessa avgränsningar ibland skär datalagret men inte den äldre skogen ute i verkligheten. Lagret innefattar inte historiska avverkningar av äldre skogar och kontinuitetsskogar. Sett över hela skogslandskapet har svenskt skogsbruk redan avverkat större delen av den äldre skogen med höga naturvärden innan publiceringen av skogsmonitor.se och processen pågår fortfarande.</p>',
    'layerDescriptions.NationalParks': 'Syftet med en nationalpark är att \'bevara ett större sammanhängande område av viss landskapstyp i dess naturliga tillstånd eller i väsentligt oförändrat skick\' (ur miljöbalken). Staten äger all mark i en nationalpark. Nationalparkerna ska utgöras av representativa landskapstyper som bevaras i naturligt tillstånd men de ska också vara natursköna eller unika miljöer som kan ge starka naturupplevelser. Sverige har 30 nationalparker.',
    'layerDescriptions.NatureReserves': 'Länsstyrelserna och kommunerna kan bilda naturreservat enligt miljöbalken. Naturreservat bildas i \'syfte att bevara biologisk mångfald, vårda och bevara värdefulla naturmiljöer eller tillgodose behov av områden för friluftslivet. Ett område som behövs för att skydda, återställa eller nyskapa värdefulla naturmiljöer eller livsmiljöer för skyddsvärda arter får också förklaras som naturreservat.\' (ur miljöbalken). Sverige har drygt 5 100 naturreservat.',
    'layerDescriptions.Nature2000SCI': 'Habitatdirektivet (direktiv 92/43/EEG) tillkom 1992. Det behandlar naturtyper samt andra artgrupper än fåglar. Begreppet habitat används mycket brett och innefattar såväl geologiska formationer som biotoper och växtsamhällen. Artgrupperna i direktivet är däggdjur, däribland varg, vissa säloch fladdermusarter, groddjur som klockgroda, fiskar som lax och stensimpa, mollusker som flodpärlmussla, kärlväxter som till exempel guckusko och mossor som hårklomossa. Lavar, svampar och alger ingår inte i direktivet. Totalt listas över 170 naturtyper i bilaga 1 till habitatdirektivet, varav 88 finns i Sverige. Varje medlemsland ska dels föreslå områden av gemenskapsintresse med dels berörda naturtyper (bilaga 1), dels arter som nämns (bilaga 2) och dels se till att nödvändiga åtgärder vidtas i områdena för att bevara arternas livsmiljöer så att de kan fortleva i livskraftiga bestånd och att naturtyperna bevaras långsiktigt. För både habitatoch fågeldirektivets områden gäller att medlemslandet ska se till att Natura 2000-områdena får den skötsel de behöver samt övervaka att deras naturvärden bevaras så att tillståndet för berörda naturtyper och arter förblir gynnsamt. De olika naturtyperna i direktivet definieras i EU-kommissionens tolkningsmanual (Interpretation Manual). Utifrån den har Naturvårdsverket tagit fram boken Svenska naturtyper.',
    'layerDescriptions.ForestBiotopeProtectionAreas': 'Biotopskyddsområde används för att bevara mindre livsmiljöer för nationellt hotade arter och små skyddsvärda naturtyper i skogs- eller jordbrukslandskapet. Skogliga biotopskyddsområden hanteras av Skogsstyrelsen.',
    'layerDescriptions.OtherBiotopeProtectionAreas': 'Biotopskyddsområde används för att bevara mindre livsmiljöer för nationellt hotade arter och små skyddsvärda naturtyper i skogs- eller jordbrukslandskapet. Skogliga biotopskyddsområden hanteras av Skogsstyrelsen.',
    'layerDescriptions.NatureConservationAgreements': 'Naturvårdsavtal som tecknats mellan fastighetsägare och Naturvårdsverket eller länsstyrelse som avtalspart för staten. Naturvårdsavtal är ett civilrättsligt avtal. Fastighetsägaren och staten eller en kommun kommer överens om en viss ekonomisk ersättning för fastighetsägaren mot att denne avstår från till exempel skogsbruk. Naturvårdsavtal kan träffas både för områden som är beroende av skötsel för att bevara naturvärdena och för sådana områden där naturvärdena gynnas bäst av fri utveckling. Avtalstiden kan variera mellan 1 och 50 år. Skogsstyrelsen och Naturvårdsverket vägleder tillsammans hur man kan gå till väga.',
    'layerDescriptions.NatureConservationAgreementsOther': 'Naturvårdsavtalet är ett civilrättsligt avtal som tecknas mellan Skogsstyrelsen, länsstyrelse eller kommun och markägare som är intresserade av naturvård. Syftet med avtalet är att bevara och utveckla områden med höga naturvärden. Avtalstiden kan variera mellan 1 och 50 år och utgör en del av det långsiktiga skogsskyddet. Markägaren får viss ekonomisk ersättning för de begränsningar i brukandet som avtalet medför. Ersättningen grundas på områdets skogliga ekonomiska värde (bedömt rotnetto) och avtalstidens längd. Naturvårdsavtal kan träffas både för områden som är beroende av skötsel för att bevara naturvärdena och för sådana områden där naturvärdena gynnas bäst av fri utveckling. Områdena har vanligtvis en storlek på mellan 3 till 20 hektar men det förekommer områden på mer än 100 hektar.',
    'layerDescriptions.Nature2000SPA': 'År 1979 beslöt EU-länderna att införa särskilda regler för skydd av fåglar, fågeldirektivet (direktiv 79/409/EEG). Fågeldirektivet berör totalt 200 fågelarter som förekommer i medlemsländerna. Av dem finns 66 i Sverige (se fågellistan i pdf-biblioteket). Varje medlemsland ska dels vidta åtgärder som är nödvändiga för att bibehålla fågelarter i livskraftiga populationer (exempelvis reglera fågeljakten) och dels vidta särskilda åtgärder för fågelarter som är listade i direktivets bilaga 1. Bland annat ska särskilda skyddsområden pekas ut. Skyddet kan också handla om att återställa livsmiljöer för fåglarna. Direktivet innehåller flera regler som styr ländernas möjligheter att jaga och handla med fåglar.',
    'layerDescriptions.NatureMemorials': 'Merparten av naturminnena är inrättade genom äldre beslut och med stöd av tidigare naturvårdslagstiftning. De flesta utgörs av särskilt skyddsvärda träd, t.ex. jätteekar. Andra objekt är flyttblock och jättegrytor. Även mindre områden, som t.ex. växtplatser för ovanliga växter eller små holmar, förekommer bland naturminnena. Naturminnen kan vara både ytor och punkter.',
    'layerDescriptions.NatureConservationAreas': 'Länsstyrelsen eller kommunen kunde innan miljöbalken trädde i kraft avsätta naturvårdsområden med stöd av naturvårdslagen. Skyddsformen var svagare än naturreservatet och fick inte hindra pågående markanvändning, som till exempel skogsbruk. De flesta naturvårdsområden inrättades för att värna om landskapsbilden, framför allt i kusttrakter, eller för att upprätthålla skötseln av slåtterängar och betesmarker. Några kommuner har använt skyddsformen för att slå vakt om populära friluftsmarker. Sedan miljöbalken trädde i kraft har möjligheten att skapa nya naturvårdsområden upphört. Men redan bildade naturvårdsområden finns kvar och ska enligt miljöbalken numera betraktas som naturreservat.',
    'layerDescriptions.AnimalAndPlantProtectionAreas': 'Det är möjligt att skydda till exempel häckande fåglar eller sälkolonier genom att förbjuda tillträde till ett område under vissa av årets månader. De flesta av de drygt 1000 djurskyddsområden som finns i dag ligger vid kuster eller insjöar och har tillkommit för att freda sjöfågel eller säl.',
    'layerDescriptions.CulturalReserves': 'Med stöd av miljöbalken kan kommun eller länsstyrelse skydda värdefulla kulturpräglade landskapsområden som kulturreservat. De kan inrättas på såväl statlig som kommunal eller enskild mark.',
    'layerDescriptions.ScenicProtectionAreas': 'Landskapsbildsskydd är en äldre skyddsform (som bildades med stöd av 19 § naturvårdslagen) och ett begrepp som inte finns i miljöbalken. Det ersätts successivt med andra skyddsformer, men fram tills dess gäller bestämmelserna i landskapsskyddsområdena. Särskilda föreskrifter finns framtagna för varje landskapsbildsskydd. Skyddet reglerar bebyggelse, vägar och andra anläggningar som kan ha en negativ effekt på landskapsbilden. Det reglerar inte skogsbruk och jordbruk. Länsstyrelserna handlägger landskapsbildsskydd. Kontakta respektive länsstyrelse om ytterligare information efterfrågas.',
    'layerDescriptions.InterimProhibitionAreasBans': 'Ett områdes naturvärden kan skyddas i avvaktan på beslut om naturreservat. Länsstyrelsen eller kommunen kan meddela ett tillfälligt förbud (enligt Miljöbalken) att utan tillstånd vidta åtgärder som skulle kunna skada de värden man vill skydda – ett interimistiskt förbud. Exempel på åtgärder som kan strida mot syftet med tilltänkt naturreservat är avverkning, husbehovstäkt, uppförande av byggnad eller dikesrensning. Ett ofta ändamålsenligt alternativ till interimistiska förbud är att staten eller en kommun träffar ett naturvårdsavtal med fastighetsägaren med innebörd att ägaren avstår från att t.ex. avverka skog under upplåtelsetiden.',
    'layerDescriptions.SFAWoodlandKeyHabitats': 'En nyckelbiotop är ett skogsområde som från en samlad bedömning av biotopens struktur, artinnehåll, historik och fysiska miljö idag har mycket stor betydelse för skogens flora och fauna. Där finns eller kan förväntas finnas rödlistade arter.',
    'layerDescriptions.ForestrysWoodlandKeyHabitats': 'Storskogsbruket fick mandat att inventera och registrera nyckelbiotoper på eget innehav. De företag som ingår i Storskogsbrukets nyckelbiotoper är Sveaskog, SCA, Holmen Skog, Statens fastighetsverk, ett flertal stift, Gävle och Karlstad kommuner samt delar av det som tidigare ägdes av Bergvik Skog AB.',
    'layerDescriptions.SFAConservationValues': 'Under inventeringen av nyckelbiotoper har man också registrerat andra objekt som har naturvärden, utan att nå upp till samma kvalitet som en nyckelbiotop. Resultatet används till rådgivning och planering av insatser för naturvården.',
    'layerDescriptions.StateConservationValueForests': 'Visningstjänst för Skyddsvärda statliga skogar och urskogsartade skogar. Inventering av skogar på statens mark med naturvärden som motiverar bildande av naturreservat. Inventeringen utfördes av länsstyrelserna efter Naturvårdsverkets anvisningar på uppdrag av Regeringen. Inventeringen slutrapporterades 2004.',
    'layerDescriptions.SFASwampForests': 'Samlingsnamn för all skogsklädd våtmark. 3 typer, myrskog, fuktskog och strandskog. Myrskogen kan delas in i kärrskog och mosseskog. Fuktskogen delas in i översilningsskog och övrig fuktskog.',
    'layerDescriptions.NotifiedLoggingLast42Days': 'Avverkningsanmälningar som ligger inom de sex veckor som Skogsstyrelsen har på sig att kontrollera att reglerna i lagstiftningen följs och om det finns några natur- eller kulturvärden som behöver tas hänsyn till inom det avverkningsanmälda området. Anmälan till Skogsstyrelsen görs vid; föryngringsavverkning, avverkning för annat ändamål än virkesproduktion, uttag av skogsbränsle, användning av utländska trädslag, skyddsdikning och för samråd om åtgärder som väsentligt ändrar naturmiljön. I vissa fall krävs ett tillstånd. Sedan 1998 finns kartfiguren digitalt.',
    'layerDescriptions.NotifiedLoggingOlderThan42Days': 'Avverkningsanmälningar som ligger bortom de sex veckor som Skogsstyrelsen har på sig att kontrollera att reglerna i lagstiftningen följs. Anmälan till Skogsstyrelsen görs vid; föryngringsavverkning, avverkning för annat ändamål än virkesproduktion, uttag av skogsbränsle, användning av utländska trädslag, skyddsdikning och för samråd om åtgärder som väsentligt ändrar naturmiljön. I vissa fall krävs ett tillstånd. Sedan 1998 finns kartfiguren digitalt.',
    'layerDescriptions.ExecutedLogging': 'Områden som avverkats enligt skillnadsanalys i satellitbilder.',
    'layerDescriptions.NationalWetlandInventory': 'Våtmarksinventeringens (VMI) objekt. Data innehåller avgränsningen av VMI-objektet (yta), en centralpunkt samt ett ID (LOID). Skala 1:250 000. På Sverigenivå (1:6 400 000) och ner till 1:250 000 visas endast inventeringar > 1000 HA. Under 1:250 000 visas samtliga inventerade våtmarksytor oavsett storlek. OBS: Data över Jämtland och Skåne län har vissa fel, så för användning i dessa län kan det vara bättre att söka data via länsstyrelsens GIS-portal.',
    'layerDescriptions.MainLandowners': '<p><span class="legend-square" style="background-color: #0000ff;"></span>Aktiebolag med över 100&nbsp;000 ha skog<br/><span class="legend-square" style="background-color: #630000;"></span>Kommuner och regioner<br/><span class="legend-square" style="background-color: #a9ff00;"></span>Naturvårdsverket<br/><span class="legend-square" style="background-color: #8ab58e;"></span>Statens Fastighetsverk<br/><span class="legend-square" style="background-color: #f57c7c;"></span>Övriga (t.&nbsp;ex. HB, KB, allmänningar, kyrkan)<br/><span class="legend-square" style="background-color: #7a7a7a;"></span>Övriga akiebolag med över 1&nbsp;000 ha skog<br/><span class="legend-square" style="background-color: #cece66;"></span>Övriga statliga verk och myndigheter</p><p>Data beskriver fastighetsinnehavet hos stora skogsägare. Skiktet innehåller geografiskt avgränsade fastigheter som ägs av markägare med ett innehav av mer än 1000 ha skog om de ingår någon av ägarkategorierna. Privata ägare (fysisk person) ingår inte. Skiktet baseras på information från fastighetsregistret (taxeringsuppgifter och digitala registerkartan) samt naturvårdsverkets fastighetsinformation (FIDOS). Underlaget kan användas för översiktlig planering och regional statistik. Kopplingar mellan taxerad markägare och enskilda fastigheter i kartan kan saknas varför underlaget inte ska användas för att söka ut eller kontakta berörda sakägare, då behövs en noggrannare fastighetsutredning.</p>',

    'metadataTitles.NationalParks.AREA_HA': 'Areal',
    'metadataTitles.NationalParks.URSBESLDAT': 'Datum',
    'metadataTitles.NationalParks.LAN': 'Län',
    'metadataTitles.NationalParks.KOMMUN': 'Kommun',
    'metadataTitles.NationalParks.NAMN': 'Namn',
    'metadataTitles.NatureReserves.AREA_HA': 'Areal',
    'metadataTitles.NatureReserves.URSBESLDAT': 'Datum',
    'metadataTitles.NatureReserves.LAN': 'Län',
    'metadataTitles.NatureReserves.KOMMUN': 'Kommun',
    'metadataTitles.NatureReserves.NAMN': 'Namn',
    'metadataTitles.ForestBiotopeProtectionAreas.AreaTot': 'Areal',
    'metadataTitles.ForestBiotopeProtectionAreas.ArendeAr': 'Datum',
    'metadataTitles.ForestBiotopeProtectionAreas.Lan': 'Län',
    'metadataTitles.ForestBiotopeProtectionAreas.Kommun': 'Kommun',
    'metadataTitles.ForestBiotopeProtectionAreas.Naturtyp': 'Naturtyp',
    'metadataTitles.NatureConservationAgreementsOther.AreaTot': 'Areal',
    'metadataTitles.NatureConservationAgreementsOther.DatAvtal': 'Datum',
    'metadataTitles.NatureConservationAgreementsOther.Lan': 'Län',
    'metadataTitles.NatureConservationAgreementsOther.Kommun': 'Kommun',
    'metadataTitles.NatureConservationAgreementsOther.Naturtyp': 'Naturtyp',
    'metadataTitles.NatureConservationAgreements.SHAPE_AREA': 'Areal',
    'metadataTitles.NatureConservationAgreements.DATSTART': 'Datum',
    'metadataTitles.NatureConservationAgreements.LANNAMN': 'Län',
    'metadataTitles.NatureConservationAgreements.KOMMNAMN': 'Kommun',
    'metadataTitles.NatureConservationAgreements.OBJNAMN': 'Namn',
    'metadataTitles.OtherBiotopeProtectionAreas.LAND_HA': 'Areal',
    'metadataTitles.OtherBiotopeProtectionAreas.URSBESLDAT': 'Datum',
    'metadataTitles.OtherBiotopeProtectionAreas.LAN': 'Län',
    'metadataTitles.OtherBiotopeProtectionAreas.KOMMUN': 'Kommun',
    'metadataTitles.OtherBiotopeProtectionAreas.NAMN': 'Namn',
    'metadataTitles.NatureConservationAreas.AREA_HA': 'Areal',
    'metadataTitles.NatureConservationAreas.URSBESLDAT': 'Datum',
    'metadataTitles.NatureConservationAreas.LAN': 'Län',
    'metadataTitles.NatureConservationAreas.KOMMUN': 'Kommun',
    'metadataTitles.AnimalAndPlantProtectionAreas.AREA_HA': 'Areal',
    'metadataTitles.AnimalAndPlantProtectionAreas.URSBESLDAT': 'Datum',
    'metadataTitles.AnimalAndPlantProtectionAreas.LAN': 'Län',
    'metadataTitles.AnimalAndPlantProtectionAreas.KOMMUN': 'Kommun',
    'metadataTitles.InterimProhibitionAreas.AREA_HA': 'Areal',
    'metadataTitles.InterimProhibitionAreas.URSBESLDAT': 'Datum',
    'metadataTitles.InterimProhibitionAreas.LAN': 'Län',
    'metadataTitles.InterimProhibitionAreas.KOMMUN': 'Kommun',
    'metadataTitles.CulturalReserves.AREA_HA': 'Areal',
    'metadataTitles.CulturalReserves.URSBESLDAT': 'Datum',
    'metadataTitles.CulturalReserves.LAN': 'Län',
    'metadataTitles.CulturalReserves.KOMMUN': 'Kommun',
    'metadataTitles.ScenicProtectionAreas.AREA_HA': 'Areal',
    'metadataTitles.ScenicProtectionAreas.URSPBESDAT': 'Datum',
    'metadataTitles.ScenicProtectionAreas.LAN': 'Län',
    'metadataTitles.ScenicProtectionAreas.KOMMUN': 'Kommun',
    'metadataTitles.Nature2000SCI.AREA_HA': 'Areal',
    'metadataTitles.Nature2000SCI.SCI_DATUM': 'Datum',
    'metadataTitles.Nature2000SCI.LAN': 'Län',
    'metadataTitles.Nature2000SCI.KOMMUN': 'Kommun',
    'metadataTitles.Nature2000SPA.AREA_HA': 'Areal',
    'metadataTitles.Nature2000SPA.SCI_DATUM': 'Datum',
    'metadataTitles.Nature2000SPA.LAN': 'Län',
    'metadataTitles.Nature2000SPA.KOMMUN': 'Kommun',
    'metadataTitles.SFAWoodlandKeyHabitats.Hektar': 'Areal',
    'metadataTitles.SFAWoodlandKeyHabitats.Datinv': 'Datum',
    'metadataTitles.SFAWoodlandKeyHabitats.Lan': 'Län',
    'metadataTitles.SFAWoodlandKeyHabitats.Kommun': 'Kommun',
    'metadataTitles.SFAWoodlandKeyHabitats.Objnamn': 'Namn',
    'metadataTitles.SFAWoodlandKeyHabitats.Beteckn': 'Beteckning',
    'metadataTitles.ForestrysWoodlandKeyHabitats.InkomDatum': 'Datum',
    'metadataTitles.ForestrysWoodlandKeyHabitats.Org': 'Markägare ',
    'metadataTitles.SFAConservationValues.Hektar': 'Areal',
    'metadataTitles.SFAConservationValues.Datinv': 'Datum',
    'metadataTitles.SFAConservationValues.Lan': 'Län',
    'metadataTitles.SFAConservationValues.Kommun': 'Kommun',
    'metadataTitles.SFAConservationValues.Beteckn': 'Beteckning',
    'metadataTitles.StateConservationValueForests.TOTAL_AREA': 'Areal',
    'metadataTitles.StateConservationValueForests.R': 'Datum',
    'metadataTitles.StateConservationValueForests.LAN': 'Län',
    'metadataTitles.StateConservationValueForests.KOMMUN': 'Kommun',
    'metadataTitles.StateConservationValueForests.NAMN': 'Namn',
    'metadataTitles.StateConservationValueForests.MARKAGARE': 'Markägare ',
    'metadataTitles.NationalWetlandInventory.AREAL_HA': 'Areal',
    'metadataTitles.NationalWetlandInventory.NVKLASS': 'Naturvärdesklass',
    'metadataTitles.SFASwampForests.Areal': 'Areal',
    'metadataTitles.SFASwampForests.Invdat': 'Datum',
    'metadataTitles.SFASwampForests.Lan': 'Län',
    'metadataTitles.SFASwampForests.Kommun': 'Kommun',
    'metadataTitles.SFASwampForests.Namn': 'Namn',
    'metadataTitles.SFASwampForests.Delklass': 'Klass',
    'metadataTitles.SFASwampForests.Hydrtext': 'Hydrologiskt index',
    'metadataTitles.NotifiedLoggingLast42Days.AnmaldHa': 'Areal',
    'metadataTitles.NotifiedLoggingLast42Days.Inkomdatum': 'Datum',
    'metadataTitles.NotifiedLoggingLast42Days.Lan': 'Län',
    'metadataTitles.NotifiedLoggingLast42Days.Kommun': 'Kommun',
    'metadataTitles.NotifiedLoggingLast42Days.Avverktyp': 'Avverkningstyp',
    'metadataTitles.NotifiedLoggingLast42Days.Beteckn': 'Beteckning',
    'metadataTitles.NotifiedLoggingOlderThan42Days.AnmaldHa': 'Areal',
    'metadataTitles.NotifiedLoggingOlderThan42Days.Inkomdatum': 'Datum',
    'metadataTitles.NotifiedLoggingOlderThan42Days.Lan': 'Län',
    'metadataTitles.NotifiedLoggingOlderThan42Days.Kommun': 'Kommun',
    'metadataTitles.NotifiedLoggingOlderThan42Days.Avverktyp': 'Avverkningstyp',
    'metadataTitles.NotifiedLoggingOlderThan42Days.Beteckn': 'Beteckning',
    'metadataTitles.ExecutedLogging.Natforha': 'Areal',
    'metadataTitles.ExecutedLogging.Avvdatum': 'Datum',
    'metadataTitles.ExecutedLogging.Lan': 'Län',
    'metadataTitles.ExecutedLogging.Kommun': 'Kommun',
    'metadataTitles.ExecutedLogging.Avverktyp': 'Avverkningstyp',

    'languages.sv': 'Svenska',
    'languages.en': 'English',
    'languages.sv.abbreviated': 'sv',
    'languages.en.abbreviated': 'en',

    'leafletMeasure.measure': 'Mät',
    'leafletMeasure.measureDistancesAndAreas': 'Mät avstånd och yta',
    'leafletMeasure.createNewMeasurement': 'Skapa ny mätning',
    'leafletMeasure.startCreating': 'Börja mäta genom att lägga till punkter på kartan',
    'leafletMeasure.finishMeasurement': 'Avsluta mätning',
    'leafletMeasure.lastPoint': 'Sista punkt',
    'leafletMeasure.area': 'Yta',
    'leafletMeasure.perimeter': 'omkrets',
    'leafletMeasure.pointLocation': 'Punktens läge',
    'leafletMeasure.areaMeasurement': 'Arealmätning',
    'leafletMeasure.linearMeasurement': 'Längdmätning',
    'leafletMeasure.pathDistance': 'Total linjelängd',
    'leafletMeasure.centerOnArea': 'Centrera detta område',
    'leafletMeasure.centerOnLine': 'Centrera denna linje',
    'leafletMeasure.centerOnLocation': 'Centrera vid denna punkt',
    'leafletMeasure.cancel': 'Avbryt',
    'leafletMeasure.delete': 'Radera',
    'leafletMeasure.kilometers': 'kilometer',
    'leafletMeasure.hectares': 'hektar',
    'leafletMeasure.meters': 'meter',
    'leafletMeasure.decPoint': ',',
    'leafletMeasure.thousandsSep': ' ',

    'artdatabanken.searchObservationsToolInfoTitle': 'Visa observationer från Artdatabanken',
    'artdatabanken.searchObservationsToolInfoBody': 'Markera ett område med ritverktygen i högerkanten på kartan och klicka sedan på den här knappen för att visa vilka artobservationer som har gjorts i området.',
    'artdatabanken.observationsModalTitle': 'Observationer från Artdatabanken',
    'artdatabanken.vernacularName': 'Svenskt namn',
    'artdatabanken.scientificName': 'Vetenskapligt namn',
    'artdatabanken.organismGroup': 'Organismgrupp',
    'artdatabanken.redlistCategory': 'Rödlistekategori',
    'artdatabanken.count': 'Antal observationer',
    'artdatabanken.name': 'Namn',
    'artdatabanken.redlistCategoryAbbreviated': 'Rödliste-kategori',
    'artdatabanken.countAbbreviated': 'Antal obs',
    'artdatabanken.maxObservationsInformation': 'Fler än 1000 observationer hittades i angivet område. De 1000 första observationerna visas.',
    'artdatabanken.showInSpeciesPortal': 'Visa i Artportalen',
    'artdatabanken.resetFilter': 'Återställ filtrering',
    'artdatabanken.errorTitle': 'Artdatabanken: kommunikationsfel',
    'artdatabanken.errorContent': 'Ett fel inträffade vid kommunikation med Artdatabankens servrar. Vi ber om ursäkt! Försök igen senare.',
    'artdatabanken.exportToExcel': 'Exportera till Excel',

    'toolbar.share': 'Dela',
    'toolbar.download': 'Ladda ner',
    'toolbar.species': 'Arter',
    'toolbar.location': 'Din plats',
    'toolbar.search': 'Sök',
    'toolbar.searchPlace': 'Sök plats',
    'toolbar.searchNotifiedLoggingArea': 'Sök avverkningsanmälning',

    'downloadMapModal.title': 'Ladda ner karta',
    'downloadMapModal.description': 'Kartan laddas ner som en fil i önskat format. Vid nedladdning av GeoTIFF för användning i en extern kartapplikation, var medveten om att Skogsmonitor använder projektionen Web Mercator och inte SWEREF99.',
    'downloadMapModal.png': 'PNG',
    'downloadMapModal.pdf': 'PDF',
    'downloadMapModal.tiff': 'GeoTIFF',
    'downloadMapModal.download': 'Ladda ner',

    'notifiedLoggingAreaSearchModal.identity': 'Beteckning',
    'notifiedLoggingAreaSearchModal.noMatches': 'Ingen avverkningsanmälning med denna beteckning hittades.',
};

export default sv;