import { searchArtdatabankenObservations } from '../api/artdatabanken';
import L from 'leaflet';
import LeafletDrawShape from '../models/LeafletDrawShape';
import { convertToPascalCase } from '../logic/string-helper';
import { formatDate } from '../logic/date-helper';
import { getResource } from '../resources/resource-manager';

export const searchArtdatabankenObservationsForShapes = async (shapes : LeafletDrawShape[], taxonIds: number[] | undefined = undefined) => {
    const observations : any[] = [];
    let error : string | undefined = undefined;

    // see https://github.com/biodiversitydata-se/SOS/blob/master/Docs/SearchFilter.md
    
    for(let i = 0; i < shapes.length; i++) {
        const shape = shapes[i];
        let geographics;
        switch(shape.type) {
            case 'circle':
                geographics = {
                    geometries: [
                        {
                            type: 'point',
                            coordinates: [ shape.coordinates[0].longitude, shape.coordinates[0].latitude ]
                        }
                    ],
                    maxDistanceFromPoint: shape.radius
                };
                break;
            case 'polygon':
                geographics = {
                    geometries: [
                        {
                            type: 'polygon',
                            coordinates: [
                                shape.coordinates.concat([shape.coordinates[0]]).map(o => [ o.longitude, o.latitude ])
                            ]
                        }
                    ]
                };
                break;
        }

        const taxon = taxonIds
            ? {
                ids: taxonIds,
                taxonListOperator: 'Merge'
            }
            : {
                // https://github.com/biodiversitydata-se/SOS/blob/master/Docs/SearchFilter.md#taxon-lists
                taxonListIds: [ 2 /* Signal species */, 18 /* Skogsstyrelsens naturvårdsarter */ ],
                taxonListOperator: 'Merge'
            };

        const searchObservationsResponse = await searchArtdatabankenObservations({
            query: {
                take: 1000
            },
            body: {
                geographics,
                taxon,
                output: {
                    fields: [
                        'event.startDate', 
                        'taxon.id', 
                        'taxon.vernacularName', 
                        'taxon.scientificName', 
                        'occurrence.occurrenceId', 
                        'occurrence.url', 
                        'location.decimalLatitude', 
                        'location.decimalLongitude', 
                        'taxon.attributes.organismGroup',
                        'taxon.attributes.isRedlisted',
                        'taxon.attributes.redlistCategory'
                    ]
                }
            }
        });
        if(searchObservationsResponse.error) {
            error = searchObservationsResponse.error
        } else {
            observations.push(...searchObservationsResponse.records);
        }
    }
    return {
        observations: error
            ? undefined
            : observations,
        error
    };
};

export const createArtdatabankenMarkers = (map : L.Map, observations : any[] | undefined) : L.Marker[] | undefined => {
    if(!observations) {
        return undefined;
    }

    const markers : L.Marker[] = [];
    observations.forEach(observation => {
        const icon = L.icon({
            iconUrl: '/assets/artdatabanken-marker.svg',
            iconSize: L.point(15, 15),
            iconAnchor: L.point(7, 7),
            className: 'artdatabanken-marker-icon'
        });
        markers.push(L.marker([observation.location.decimalLatitude, observation.location.decimalLongitude], { icon }).addTo(map).bindPopup(createObservationPopupElement(observation)));
    });
    return markers;
};

export const removeArtdatabankenMarkers = (map : L.Map, markers : L.Marker[] | undefined) => {
    if(markers) {
        markers.forEach(marker => {
            marker.removeFrom(map);
        });
    }
};

const createObservationPopupElement = (observation : any) : HTMLElement => {
    const element = document.createElement('div');
    element.className = 'artdatabanken-observation-popup';
    element.innerHTML =
        `<div class="vernacular-name">${convertToPascalCase(observation.taxon.vernacularName, { firstWordOnly: true })}</div>` +
        `<div class="scientific-name"><em>${observation.taxon.scientificName}</em></div>` +
        `<div class="date">${formatDate(observation.event.startDate)}</div>` +
        (observation.occurrence.url ? `<div class="sighting-link"><a href="${observation.occurrence.url}" target="_blank">${getResource('artdatabanken.showInSpeciesPortal')}</a></div>` : '');

    return element;
};